<template>
  <v-card rounded="lg" style="overflow-y: auto" :outlined="!flat" :flat="flat">
    <v-card-text class="pa-0">
      <v-data-table
        v-on="$listeners"
        v-bind="$attrs"
        :no-data-text="noDataText || $t('common.noData')"
        :loading-text="$t('common.loading')"
        :headers="styledColumns"
        :header-props="{
          sortIcon: mdiArrowDown
        }"
        :height="height"
        :loading="loading"
        :expand-icon="mdiChevronDown"
        fixed-header
        :footer-props="{
          itemsPerPageOptions: [2, 5, 10, 15, 25, 50],
          nextIcon: mdiChevronRight,
          prevIcon: mdiChevronLeft,
          lastIcon: mdiChevronDoubleLeft,
          firstIcon: mdiChevronDoubleRight,
          itemsPerPageText: $t('common.itemsPerPage'),
          pageText: $t('common.pageText')
        }"
        :items-per-page="25"
        :style="{
          opacity: loading ? 0.5 : 1
        }"
      >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

        <!-- Pass on all scoped slots -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
          ><slot :name="slot" v-bind="scope"
        /></template>
        <!-- <template #footer>
          <v-pagination />
        </template> -->
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiArrowDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDown,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight
} from '@mdi/js';
export default {
  name: 'CzDataTable',
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    noDataText: {
      type: String
    },
    height: {
      type: [String, Number]
    },
    innerTable: {
      type: Boolean,
      default: false
    },
    clickableRows: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    flat: {
      type: Boolean
    }
  },
  computed: {
    styledColumns() {
      const fontSizeClass = this.innerTable
        ? 'text-subtitle-1'
        : 'text-subtitle-1';

      const zIndex = this.innerTable ? 'inner-column-z' : 'column-z';

      return this.columns.map((column) => {
        return {
          ...column,
          class: `font-weight-semibold ${fontSizeClass} info--text ${zIndex}`
        };
      });
    },
    cursor() {
      if (this.clickableRows && !this.loading) {
        return 'pointer';
      } else {
        return 'inehirt';
      }
    },
    hoverBackground() {
      if (this.clickableRows && !this.loading) {
        return 'var(--v-buttonSecondary-lighten3)';
      } else {
        return 'transparent';
      }
    }
  },
  data() {
    return {
      mdiChevronDown,
      mdiChevronRight,
      mdiChevronLeft,
      mdiChevronDoubleLeft,
      mdiChevronDoubleRight,
      mdiArrowDown
    };
  }
};
</script>

<style scoped lang="scss">
.column-z {
  z-index: 2 !important;
}
::v-deep .inner-column-z {
  z-index: 1 !important;
}

::v-deep .v-data-table__wrapper > table > tbody > tr:hover {
  background: v-bind(hoverBackground) !important;
}
// ::v-deep tbody tr :hover {
//   cursor: v-bind(cursor);
// }
</style>
