<template>
  <cz-autocomplete
    v-on="$listeners"
    v-bind="$attrs"
    :items="items"
    outlined
    :readonly="readonly"
  >
    <template #item="{ item }">
      <div class="d-flex align-center" style="column-gap: 20px">
        <v-icon :size="16" v-if="item.icon">{{ item.icon }}</v-icon>
        <div>{{ item.text }}</div>
      </div>
    </template>
    <template #selection="{ item }">
      <div class="d-flex align-center" style="column-gap: 10px">
        <v-icon :size="16" v-if="item.icon">{{ item.icon }}</v-icon>
        <span class="text-truncate text-no-wrap">{{ item.text }}</span>
      </div>
    </template>
  </cz-autocomplete>
</template>

<script>
import CzAutocomplete from './CzAutocomplete.vue';
export default {
  name: 'CzCurrencyPicker',
  components: {
    CzAutocomplete
  },
  props: {
    // Array with text, value, icon
    items: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
