<template>
  <v-chip
    :dark="dark"
    :close="removeable"
    :label="!circleEdges"
    :color="`${color} lighten-5`"
    v-bind="$attrs"
    v-on="$listeners"
    :small="small"
    :close-icon="mdiClose"
    @click:close="$emit('remove')"
    :class="`${color}--text`"
  >
    <slot>
      <v-avatar v-if="avatarSrc">
        <img :src="avatarSrc" />
      </v-avatar>
      <cz-icon
        left
        v-if="iconSrc"
        :src="iconSrc"
        :color="color"
        class="ml-2"
        :size="iconSize"
      />
      <div class="text-truncate font-weight-semibold" :class="`${color}--text`">
        {{ text }}
      </div>
    </slot>
  </v-chip>
</template>

<script>
import { mdiClose } from '@mdi/js';
import CzIcon from './CzIcon.vue';
export default {
  name: 'CzChip',
  components: {
    CzIcon
  },
  props: {
    text: {
      type: String
    },
    textColor: {
      type: String,
      default: 'info'
    },
    color: {
      type: String,
      default: 'buttonSecondary'
    },
    dark: {
      type: Boolean,
      default: false
    },
    circleEdges: {
      type: Boolean,
      default: false
    },
    removeable: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    avatarSrc: {
      type: String
    },
    iconSrc: {
      type: String
    },
    iconSize: {
      type: Number,
      default: 16
    }
  },
  data() {
    return {
      mdiClose
    };
  }
};
</script>

<style lang="scss" scoped></style>
