<template>
  <v-card
    rounded="lg"
    :height="height"
    :width="width"
    :hover="clickable"
    outlined
    @click="clickable ? $emit('click') : undefined"
    :ripple="false"
    :style="{
      cursor: clickable ? 'pointer' : 'default',
      pointerEvents: clickable ? 'auto' : 'none'
    }"
  >
    <img v-if="imageSrc" :src="imageSrc" width="100%" :height="158" />
    <div
      v-else-if="videoId"
      class="youtube-embed-card"
      style="pointer-events: auto"
    >
      <cz-youtube
        :video-id="videoId"
        :player-height="158"
        :player-vars="{ start: videoStart, autoplay: 0 }"
        :player-width="'100%'"
      />
    </div>

    <v-card-title
      class="text-truncate text-no-wrap textPrimary--text font-weight-regular"
      >{{ title }}</v-card-title
    >
    <v-card-subtitle v-if="count">
      <cz-chip
        small
        color="info"
        outlined
        class="font-weight-semibold"
        :text="count"
      ></cz-chip>
    </v-card-subtitle>
    <v-card-text v-if="subtitle">{{ subtitle }}</v-card-text>
  </v-card>
</template>

<script>
import { CzChip } from '@/components';
export default {
  name: 'CzDashboardCard',
  components: {
    CzChip
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    count: {
      type: [String, Number]
    },
    imageSrc: {
      type: String
    },
    videoId: {
      type: String
    },
    clickable: {
      type: Boolean
    },
    videoStart: {
      type: Number,
      default: 0
    },
    width: {
      type: [String, Number],
      default: 240
    },
    height: {
      type: [String, Number],
      default: 310
    }
  },
  computed: {
    cardHeight() {
      if (this.count) {
        return (this.height += 20);
      } else {
        return this.height;
      }
    }
  }
};
</script>

<style scoped>
svg {
  fill: transparent !important;
}

.youtube-embed iframe {
  border-radius: 20px !important;
}
</style>

<style>
.youtube-embed-card iframe {
  /* border-radius: 10px !important; */
  border-radius: 10px 10px 0px 0px !important;
}
</style>
