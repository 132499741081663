import Vue from 'vue';
import App from './App.vue';
import './styles/main.scss';
import router from './router';
import store from './core/store';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { setup } from '@/shared/validators';
import { he } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import VueSignaturePad from 'vue-signature-pad';
import VueMask from 'v-mask';
import vueDebounce from 'vue-debounce';
import Clipboard from 'v-clipboard';
import VueYouTubeEmbed from 'vue-youtube-embed';
import { VueReCaptcha } from 'vue-recaptcha-v3';

setDefaultOptions({
  locale: he
});

Vue.config.productionTip = false;

Vue.use(VueSignaturePad);
Vue.use(VueYouTubeEmbed, { global: true, componentId: 'cz-youtube' });
Vue.use(VueMask);
Vue.use(vueDebounce);
Vue.use(Clipboard);
if (process.env.VUE_APP_RECAPCHA_ENABLED) {
  Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPCHA_SITE_ID });
}

// register vee-validate globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

setup(i18n);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
