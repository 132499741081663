<template>
  <div class="d-flex flex-column" style="row-gap: 2px">
    <div
      class="d-flex justify-space-between align-center mb-1"
      style="gap: 20px"
    >
      <div
        :class="labelClass"
        class="d-flex align-center textPrimary--text flex-grow-1"
      >
        <cz-icon
          v-if="iconSrc"
          color="textPrimary lighten-3"
          :src="iconSrc"
          :size="16"
          class="ml-2"
        />
        <div>{{ required ? `* ${label}` : `${label}` }}</div>
      </div>
      <cz-menu-button
        v-if="tooltip"
        :button-icon-src="mdiInformationOutline"
        flat
        color="primary"
        :text="tooltip"
        :title="label"
        :menu-max-width="250"
        button-small
      />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js';
import CzButton from './CzButton.vue';
import CzMenuButton from './CzMenuButton.vue';
import CzIcon from './CzIcon.vue';
export default {
  name: 'CzFormField',
  components: {
    CzButton,
    CzIcon,
    CzMenuButton
  },
  props: {
    label: {
      type: String,
      required: false
    },
    labelColor: {
      type: String,
      default: 'textSecondary'
    },
    large: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: undefined
    },
    iconSrc: {
      type: String
    }
  },
  computed: {
    labelClass() {
      return {
        [`${this.labelColor}--text`]: true,
        'text-caption': !this.large,
        'text-subtitle-1': this.large,
        'font-weight-semibold': this.large
      };
    }
  },
  data() {
    return {
      mdiInformationOutline
    };
  }
};
</script>

<style lang="scss" scoped>
.label {
  color: #849baf;
}
</style>
